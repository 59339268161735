export default [{
  path: '/',
  component: () => import('@/views/layout'),
  children: [{
    path: '/',
    name: 'index',
    component: () => import('@/views/home/home'),
    meta: {
      title: '首页'
    }
  },{
    path: '/product',
    name: 'product',
    component: () => import('@/views/home/product'),
    meta: {
      title: '产品服务'
    }
  },{
    path: '/case',
    name: 'case',
    component: () => import('@/views/404'),
    meta: {
      title: '客户案例'
    }
  },{
    path: '/member',
    name: 'member',
    component: () => import('@/views/404'),
    meta: {
      title: '会员中心'
    }
  },{
    path: '/about',
    name: 'about',
    component: () => import('@/views/home/about'),
    meta: {
      title: '关于元数'
    }
  }]
}]
