import Vue from "vue"

import {
  Button,
  Input,
  Carousel,
  CarouselItem,
  Icon,
  Form,
  FormItem,
  Checkbox,
  Radio,
  Select,
  Option,
  Message
} from 'iview'

Vue.component('Button', Button)
Vue.component('Input', Input)
Vue.component('Carousel', Carousel)
Vue.component('CarouselItem', CarouselItem)
Vue.component('Icon', Icon)
Vue.component('Form', Form)
Vue.component('FormItem', FormItem)
Vue.component('Checkbox', Checkbox)
Vue.component('Radio', Radio)
Vue.component('Select', Select)
Vue.component('Option', Option)

Vue.prototype.$Message = Message
