import Vue from 'vue'
import Router from 'vue-router'
// import store from '@/store'
import home from './home'

Vue.use(Router)

const routes = [
  ...home,
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register'),
    meta: {
      title: '申请试用'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/register/privacy'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/register/service'),
    meta: {
      title: '用户协议'
    }
  }
]

// 定义路由实例
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) { // 路由切换保持滚动条回到顶部
    // return savedPosition || to.hash || {x: 0, y: 0}
    return savedPosition || { x: 0, y: 0 }
  }
})

export default router